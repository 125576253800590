<template>
  <div class="success-modal" ref="successModalRef">
    <a-modal
      v-model:open="props.showSuccessModal"
      :closable="false"
      :width="480"
      :getContainer="() => successModalRef"
      :footer="null"
      wrapClassName="success-modal-content"
      :onCancel="() => props.setShowSuccessModal(false)"
      :maskClosable="false"
    >
      <div class="content">
        <successChenchongSvg />
        <p>Please check your email for a login link！</p>
        <a-button @click="resendEmail">Resend Email</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import successChenchongSvg  from '../../components/svg/success-chenchong.vue';

  const props = defineProps(['showSuccessModal', 'setShowSuccessModal', 'resendEmail']);

  const successModalRef = ref(null);

</script>
<style lang="less">
  .success-modal {
    .success-modal-content {
      .content {
        display: flex;
        flex-direction: column;
        > svg {
          margin-top: 40px;
          width: 64px;
          height: 64px;
          align-self: center;
          path {
            fill: #00CE06;
          }
        }
        > p {
          color: #0f0629;
          font-size: 16px;
          font-family: Sora;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.4px;
          text-align: center;
          margin-top: 12px;
        }
        > button {
          align-self: center;
          width: 162px;
          height: 40px;
          background: #FFFFFF;
          border: 2px solid #CCC7D9;
          box-shadow: 0 1px 0 0 #1a16250d;
          border-radius: 20px;
          margin-top: 24px;
          opacity: 0.7;
          font-family: Sora-Medium;
          font-weight: 500;
          font-size: 16px;
          color: #0F0629;
          letter-spacing: 0.4px;
          text-align: center;
          line-height: 22px;
        }
      }
      .ant-modal-body {
        border-bottom: none;
      }
      .ant-modal {
        top: 150px;
      }
    }
  }
</style>